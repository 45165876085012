import { Methodology, StaticEntity, request } from '.';

export const defaultRequiredMessage = 'This field is required';

export enum BillingModeType {
  Free = 'free',
  Standalone = 'standalone',
  Volume = 'volume',
}

export interface CustomerForm extends Customer {
  methodology: StaticEntity<Methodology>;
  addDemoProducts: boolean;
}

interface CustomerPermissions {
  customerManagement: boolean;
  globalUserManagement: boolean;
  globalWorkspaceManagement: boolean;
  workspaceAudit: boolean;
}

interface WorkspacePermissions {
  productManagement: boolean;
}

export interface Customer {
  customerSid: string;
  companyName: string;
  logoUrl?: string;
  permissions: CustomerPermissions;
  defaultWorkspace: Workspace;
}

export interface Workspace {
  customerSid: string;
  workspaceSid: string;
  name: string;
  integrationName?: string;
  logoUrl?: string;
  brand: StaticEntity;
  methodology: StaticEntity<Methodology>;
  limits: {
    products: number;
    modelsPerProduct: number;
    manufacturingReports: number;
    specUploads: number;
  };
  counters: {
    uploadedSpecs: number;
  };
  permissions: WorkspacePermissions;
  targetSkuCount: number;
  trialDays?: number;
  trialUntil: string | null;
  billingMode: StaticEntity<BillingModeType>;
}

export interface NewUserForm extends User {
  requirePasswordChange: boolean;
}

export interface User {
  customerSid: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  company: string;
  job: string;
  internalUser: boolean;
}

export const getCustomers = () => request<Customer[]>('GET', '/v3/customers');
export const getCustomer = (customerId: string) => request<Customer>('GET', `/v3/customers/${customerId}`);
export const createCustomer = (payload: Customer) => request<Customer>('POST', '/v3/customers', { body: payload });

export const getWorkspaces = (customerSid: string) => request<Workspace[]>('GET', `/v3/workspaces?customerSid=${customerSid}`);
export const getWorkspace = (workspaceSid: string) => request<Workspace>('GET', `/v3/workspaces/${workspaceSid}`);
export const createWorkspace = (payload: Workspace) =>
  request<Workspace & ({ errorCode: string; message: string } | undefined)>('POST', '/v3/workspaces', { body: payload });
export const updateWorkspace = (payload: Workspace) =>
  request<Workspace & ({ errorCode: string; message: string } | undefined)>('PUT', `/v3/workspaces/${payload.workspaceSid}`, {
    body: payload,
  });

export const getUsers = (customerSid: string) => request<User[]>('GET', `/v3/users?customerSid=${customerSid}`);
export const createUser = (payload: User) => request<User & { errorCode?: string }>('POST', '/v3/users', { body: payload });
export const deleteUser = (email: string) => request('DELETE', `/v3/users/${email}`);
